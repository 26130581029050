<template>
  <div>
    <v-container v-if="props && props.expo">
      <v-row dense>
        <v-col>
            <v-img
              v-if="props && props.logo"
              :src="props.logo"
              height="150px"
              contain=""
            ></v-img>
            <h2>The {{pointSystem.name}} Point System</h2>
            <div v-if="props && props.header" v-html="props.header"></div>
        </v-col>
        <v-col cols="12" class="text-center">
          <div class="headline">
            Division Base Points x Number of Teams Entered = Total Points Earned
          </div>
          <div>1st Place = 100% of Points</div>
          <div>2nd Place = 90% of 1st Place</div>
          <div>3rd Place = 90 % of 2nd Place</div>
          <div>And so on… with each place receiving 90% of the place before it.</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" offset-md="3">
          <v-card flat class="text-center elevation-1">
            <v-toolbar dense color="color1 color1Text--text">
              <v-toolbar-title>Base Point Scale</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-0 text-center">
              <div class="title">Division Base Points</div>
              <div class="subtitle">Each division has a base number of points that never changes.</div>
              <v-data-table
                v-if="expoPoints"
                :headers="[
                  {text: 'Adults', value: 'adult', align: 'center'},
                  {text: 'Juniors', value: 'juniors', align: 'center'},
                  {text: 'Division Points', value: 'points', align: 'center'}
                ]"
                :items="expoPoints"
                :options.sync="pager"
                hide-default-footer
                dense
                :mobile-breakpoint="0"
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="props && props.footer">
        <v-col cols="12" v-html="props.footer"></v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid :class="{'pa-0': !isTab}">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-switch
            label="Published"
            v-model="model.published"
            color="color3"
            :disabled="!editing"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-text-field
            label="Short Name"
            v-model="model.shortName"
            color="color3"
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :items="[
              { text: 'Standard', value: 'standard' },
              { text: 'Exponential', value: 'expo' }
            ]"
            v-model="model.type"
            label="Type"
            color="color3"
            item-color="color3"
            :disabled="!editing || true"
          ></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12"></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="px-0">
          <v-card flat class="text-center elevation-1">
            <v-toolbar dense color="color1 color1Text--text">
              <v-toolbar-title>Base Point Scale</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="6" lg="4" xl="3" v-for="table in tables" :key="table.title">
                  <v-card>
                    <v-toolbar dense color="color2 color2Text--text">
                      <v-toolbar-title>{{table.title}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="table.rows"
                        :options.sync="pager"
                        hide-default-footer
                        dense
                        :mobile-breakpoint="0"
                      >

                        <template v-slot:[`item.finish`]="{item}">
                          <span v-if="item.finish < 100000">{{item.finish | ordinal}}</span>
                          <span v-else>All others</span>
                        </template>
                        <template v-slot:[`item.points`]="{item}">
                          <v-edit-dialog
                            :disabled="!editing"
                            :return-value.sync="item.points"
                          > {{item.points}}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.points"
                                type="number"
                                label="Points"
                                single-line
                                color="color3"
                                :disabled="!editing"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.noDisplay`]="{item}">
                          <v-checkbox
                            class="mt-0"
                            v-model="item.noDisplay"
                            hide-details single-line
                            color="success"
                            :disabled="!editing"
                          ></v-checkbox>
                        </template>
                        <template v-slot:[`item.perTeam`]="{item}">
                          <v-checkbox
                            class="mt-0"
                            v-model="item.perTeam"
                            :value="true"
                            hide-details single-line
                            color="success"
                            :disabled="!editing"
                          ></v-checkbox>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense v-if="multipliers.length">
        <v-col>
          <v-card class="text-center">
            <v-toolbar dense color="color1 color1Text--text">
              <v-toolbar-title>Team Participation Multiplier</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-data-table
                :headers="mHeaders"
                :items="multipliers"
                hide-default-footer
                :loading="loading"
                :options="{itemsPerPage: -1}"
                dense
              >
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-fab-transition>
      <v-btn
        fab fixed bottom right
        style="margin-right: 60px"
        v-if="editing"
        :disabled="!dirty"
        :key="dirty"
        color="success white--text"
        @click.stop="save"
        :loading="loading"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-btn
        fab fixed bottom right
        :key="rightFab.icon"
        :color="rightFab.color"
        @click.stop="rightFab.click"
        :disabled="loading"
      >
        <v-icon>{{rightFab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import uniq from 'lodash.uniq'
import { firstBy } from 'thenby'
// import each from 'lodash.foreach'

export default {
  props: ['isTab', 'pointSystem', 'tournamentOptions'],
  data () {
    return {
      loading: false,
      headers: [
        { text: 'Finish', align: 'left', sortable: false, value: 'finish' },
        { text: 'Points', align: 'left', sortable: false, value: 'points' },
        { text: 'Hidden', align: 'left', sortable: false, value: 'noDisplay' },
        { text: 'Per Team', align: 'left', sortable: false, value: 'perTeam' }
      ],
      pager: { sortBy: ['points'], itemsPerPage: -1, sortDesc: [true] },
      mHeaders: [
        { text: 'Teams', align: 'left', sortable: false, value: 'description' },
        { text: 'Point Multiplier', align: 'left', sortable: false, value: 'multiplier' }
      ],
      pointScale2: null,
      multipliers2: null,
      editing: false,
      editable: null
    }
  },
  computed: {
    rightFab () {
      return this.editing ? {
        icon: 'fas fa-times',
        color: 'error white--text',
        click: this.cancel
      } : {
        icon: 'fas fa-pencil',
        color: 'color3 color3Text--text',
        click: this.edit
      }
    },
    model () {
      return this.editing ? this.editable : this.pointSystem
    },
    dto () {
      if (!this.editable || !this.dirty) return null
      var dto = {
        id: this.pointSystem.id,
        pointValues: []
      }

      this.editable.pointValues.forEach(pv => {
        const d = this.pointSystem.pointValues.find(f => f.id === pv.id)
        if (d && JSON.stringify(d) !== JSON.stringify(pv)) dto.pointValues.push(pv)
      })

      return dto
    },
    dirty () {
      return JSON.stringify(this.pointSystem) !== JSON.stringify(this.editable)
    },
    tables () {
      if (!this.pointScale) return []
      const tables = uniq(this.pointScale.map(p => p.division)).sort().reverse()
      return tables.map((table) => {
        return { title: table, rows: this.pointScale.filter(p => p.division === table) }
      })
    },
    pointScale () {
      return this.model.pointValues.map(m => {
        const d = this.tournamentOptions.divisionOptions.find(f => f.id === m.divisionId)
        if (d) m.division = d.name
        return m
      })
    },
    multipliers () {
      return this.model.teamCountMultipliers
    },
    props () {
      return this.pointSystem.jsonProps && JSON.parse(this.pointSystem.jsonProps)
    },
    expoPoints () {
      if (this.pointScale2) {
        var points = uniq(this.pointScale2.map(p => p.points))
        return points.sort(firstBy(Number, -1)).map(m => {
          const a = this.pointScale2.find(f => f.points === m && f.ageType === 2)
          const j = this.pointScale2.find(f => f.points === m && f.ageType === 1)
          return {
            points: m,
            adult: a ? a.division : '-',
            juniors: j ? j.division : '-'
          }
        })
      }
      return null
    }
  },
  methods: {
    edit () {
      this.editable = JSON.parse(JSON.stringify(this.pointSystem))
      this.editing = true
    },
    cancel () {
      this.editable = null
      this.editing = false
    },
    getScale () {
      this.loading1 = true
      this.$VBL.getPointScale(`${this.pointSystem.id}`)
        .then((response) => {
          this.pointScale2 = response.data
          this.loading1 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading1 = false
        })
    },
    getMultipliers () {
      this.loading2 = true
      this.$VBL.getMultipliers(`${this.pointSystem.id}`)
        .then((response) => {
          this.multipliers2 = response.data
          this.loading2 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading2 = false
        })
    },
    save () {
      this.loading = true
      this.$VBL.points.post(this.dto)
        .then(r => {
          this.$emit('update', r.data)
          this.cancel()
        })
        .catch(e => console.log(e.response))
        .finally(f => {
          this.loading = false
        })
    }
  },
  created () {
    this.getScale()
    this.getMultipliers()
  }
}
</script>

<style>

</style>
