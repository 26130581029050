var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.props && _vm.props.expo)?_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.props && _vm.props.logo)?_c('v-img',{attrs:{"src":_vm.props.logo,"height":"150px","contain":""}}):_vm._e(),_c('h2',[_vm._v("The "+_vm._s(_vm.pointSystem.name)+" Point System")]),(_vm.props && _vm.props.header)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.header)}}):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline"},[_vm._v(" Division Base Points x Number of Teams Entered = Total Points Earned ")]),_c('div',[_vm._v("1st Place = 100% of Points")]),_c('div',[_vm._v("2nd Place = 90% of 1st Place")]),_c('div',[_vm._v("3rd Place = 90 % of 2nd Place")]),_c('div',[_vm._v("And so on… with each place receiving 90% of the place before it.")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('v-card',{staticClass:"text-center elevation-1",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Base Point Scale")])],1),_c('v-card-text',{staticClass:"px-0 text-center"},[_c('div',{staticClass:"title"},[_vm._v("Division Base Points")]),_c('div',{staticClass:"subtitle"},[_vm._v("Each division has a base number of points that never changes.")]),(_vm.expoPoints)?_c('v-data-table',{attrs:{"headers":[
                {text: 'Adults', value: 'adult', align: 'center'},
                {text: 'Juniors', value: 'juniors', align: 'center'},
                {text: 'Division Points', value: 'points', align: 'center'}
              ],"items":_vm.expoPoints,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0},on:{"update:options":function($event){_vm.pager=$event}}}):_vm._e()],1)],1)],1)],1),(_vm.props && _vm.props.footer)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.props.footer)}})],1):_vm._e()],1):_c('v-container',{class:{'pa-0': !_vm.isTab},attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-switch',{attrs:{"label":"Published","color":"color3","disabled":!_vm.editing},model:{value:(_vm.model.published),callback:function ($$v) {_vm.$set(_vm.model, "published", $$v)},expression:"model.published"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Short Name","color":"color3","disabled":!_vm.editing},model:{value:(_vm.model.shortName),callback:function ($$v) {_vm.$set(_vm.model, "shortName", $$v)},expression:"model.shortName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-select',{attrs:{"items":[
            { text: 'Standard', value: 'standard' },
            { text: 'Exponential', value: 'expo' }
          ],"label":"Type","color":"color3","item-color":"color3","disabled":!_vm.editing || true},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}})],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"text-center elevation-1",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Base Point Scale")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.tables),function(table){return _c('v-col',{key:table.title,attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(table.title))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":table.rows,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"item.finish",fn:function(ref){
          var item = ref.item;
return [(item.finish < 100000)?_c('span',[_vm._v(_vm._s(_vm._f("ordinal")(item.finish)))]):_c('span',[_vm._v("All others")])]}},{key:"item.points",fn:function(ref){
          var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"disabled":!_vm.editing,"return-value":item.points},on:{"update:returnValue":function($event){return _vm.$set(item, "points", $event)},"update:return-value":function($event){return _vm.$set(item, "points", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Points","single-line":"","color":"color3","disabled":!_vm.editing},model:{value:(item.points),callback:function ($$v) {_vm.$set(item, "points", $$v)},expression:"item.points"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.points)+" ")])]}},{key:"item.noDisplay",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","single-line":"","color":"success","disabled":!_vm.editing},model:{value:(item.noDisplay),callback:function ($$v) {_vm.$set(item, "noDisplay", $$v)},expression:"item.noDisplay"}})]}},{key:"item.perTeam",fn:function(ref){
          var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"value":true,"hide-details":"","single-line":"","color":"success","disabled":!_vm.editing},model:{value:(item.perTeam),callback:function ($$v) {_vm.$set(item, "perTeam", $$v)},expression:"item.perTeam"}})]}}],null,true)})],1)],1)],1)}),1)],1)],1)],1)],1),(_vm.multipliers.length)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"text-center"},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Team Participation Multiplier")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.mHeaders,"items":_vm.multipliers,"hide-default-footer":"","loading":_vm.loading,"options":{itemsPerPage: -1},"dense":""}})],1)],1)],1)],1):_vm._e()],1),_c('v-fab-transition',[(_vm.editing)?_c('v-btn',{key:_vm.dirty,staticStyle:{"margin-right":"60px"},attrs:{"fab":"","fixed":"","bottom":"","right":"","disabled":!_vm.dirty,"color":"success white--text","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.save($event)}}},[_c('v-icon',[_vm._v("fas fa-save")])],1):_vm._e()],1),_c('v-fab-transition',[_c('v-btn',{key:_vm.rightFab.icon,attrs:{"fab":"","fixed":"","bottom":"","right":"","color":_vm.rightFab.color,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.rightFab.click($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.rightFab.icon))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }