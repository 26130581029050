<template>
  <v-card flat tile>
    <loading-bar :value="!!loading.length"></loading-bar>
    <v-expand-transition>
      <point-system-manager
        v-if="pointSystem && options"
        :isTab="true"
        :pointSystem="pointSystem"
        :tournamentOptions="options"
        @update="onUpdate"
      ></point-system-manager>
    </v-expand-transition>
  </v-card>
</template>

<script>
import PointSystemManager from '@/components/Points/PointSystemManager'

export default {
  props: ['username'],
  data () {
    return {
      loading: [],
      pointSystem: null,
      options: null
    }
  },
  methods: {
    loadPointSystem () {
      this.loading.push(1)
      this.$VBL.points.get(this.username)
        .then(r => { this.pointSystem = r.data })
        .catch(e => console.log(e))
        .finally(f => { this.loading = this.loading.filter(f => f !== 1) })
    },
    loadOptions () {
      this.loading.push(2)
      this.$http.get(`${window.location.origin}/static/json/TournamentOptions.json`)
        .then(r => { this.options = r.data })
        .finally(f => { this.loading = this.loading.filter(f => f !== 2) })
    },
    onUpdate (dto) {
      this.pointSystem = dto
    }
  },
  mounted () {
    this.loadPointSystem()
    this.loadOptions()
  },
  components: {
    PointSystemManager
  }
}
</script>
